import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Kys = () => {
  const headingbanner = {
    title: `KYS`,
    content: `Supplier GST Performance Platform`,
  };

  const data = {
    images: ["kys.webp", "kys-2.webp"],
    firstAlt: "UI UX of supplier’s GST compliance overview screen",
    firstTitle: "Dashboard",
    secondAlt: "Suppliers GST compliance solutions",
    secondTitle: "Suppliers Solutions",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: ["User Research", "UI UX Designing", "Angular Development"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `One of the primary obstacles we faced was the immense amount of data that needed to be analyzed and interpreted. `,
          `We had to create a smart dashboard that would provide intuitive data visualization and information that would be logical and easy for users to comprehend. `,
          `Additionally, we strived to develop predictive models that would enable users to anticipate their supplier's behaviour.`,
          `By combining the power of data visualization and predictive analytics, we had to empower users to make informed decisions and proactively manage their supplier relationships.`
        ],
      },
      {
        title: "Solution",
        content: [
          `Our main objective was to develop a state-of-the-art fintech product that leverages user insights and our design expertise. `,
          `We aimed to surpass our competitors by creating an intuitive and user-friendly design, complemented by in-depth insights into customer behavior and preferences. `,
          `Additionally, we sought to track suppliers to assist users in making intelligent decisions regarding their procurement process. `,
          `A critical aspect of our product was the ability to generate customizable reports tailored to suit the unique needs of our users. `,
          `By achieving these goals, we aimed to revolutionize the way individuals and businesses manage their finances and streamline their processes.`
        ],
      },
    ],
    image: ["kys-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: `Home page`,
    para: [
      `We created a holistic platform to understand supplier’s behaviour in order to prevent major losses and optimise the transactions.`,
    ],
    content: {
      image: ["kys-projectone.webp"],
      imageAlt: 'UI of GST Solutions screen',
      imageTitle: 'Solutions',
    },  
  };

  const projectData2 = {
    title: `Supplier Information`,
    para: [
      `We created a dedicated interface for every supplier to know their GST filling performance and compliance status. `,
    ],
    content: {
      image: ["kys-projecttwo.webp"],
      imageAlt: 'Supplier details and GST Filling Performance',
      imageTitle: 'Supplier Details',
    },
  };

  const projectData3 = {
    title: `Login Screen`,
    para: [
      `We created a login screen to enhance security, facilitate personalisation, user authentication and offer enhanced performance.`,
    ],
    content: {
      image: [
        "kys-projectthree.webp",
        "kys-projectthree-2.webp",
        "kys-projectthree-3.webp",
        "kys-projectthree-4.webp",
        "kys-projectthree-5.webp",
        "kys-projectthree-6.webp",
      ],
      firstAlt: "Supplier's login screen",
      firstTitle: 'Login Screen',
      secondAlt: 'UI of GST Solutions screen',
      secondTitle: 'Solutions',
      thirdAlt: 'UI Dashboard to see supplier overview, GST rating and compliance status',
      thirdTitle: 'Suppliers Dashboard',
      fourthAlt: "UI screen to check supplier's monthly GST report",
      fourthTitle: 'Reports',
      fifthAlt: 'Supplier details and GST Filling Performance',
      fifthTitle: 'Supplier Details',
      sixthAlt: "Filter Supplier's report",
      sixthTitle: 'Reports',
    },
  };

  const conclusionData = [
    {
      para: [
        `Our team successfully tackled the challenge of analyzing and interpreting large data sets by developing a smart dashboard that offers user-friendly data visualization. `,
        `With our predictive models, users are able to predict and manage their supplier's behaviour, empowering them to make informed decisions and proactively handle their supplier relationships. `,
        `Moreover, our advanced fintech product exceeds our competitors by providing a user-friendly and intuitive design, accompanied by valuable insights into customer preferences and behaviour. `
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={31} />
      </div>
    </Layout>
  );
};

export default Kys;

export const Head = () => (
  <Seo title="Know Your Supplier - Octet Design Studio" description="We did User Research, UI UX designing & UI development for a fintech product - GST compliance verification & performance evaluation platform." />
)